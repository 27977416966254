import React from "react"
import { Layout, Hero, Posts } from "../components"
import { graphql } from "gatsby"

const CategoryTemplate = props => {
  const {
    pageContext: { category },
  } = props

  const {
    data: {
      categories: { nodes: posts },
    },
  } = props
  return (
    <Layout>
      <Hero />
      <Posts posts={posts} title={`category / ${category}`} />
    </Layout>
  )
}

export const query = graphql`
  query GetCategories($category: String) {
    categories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "MMM Do, YYYY")
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          readTime
          slug
          category
          author
        }
        id
      }
    }
  }
`

export default CategoryTemplate
